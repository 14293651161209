
















































import wAxios from '@/plugins/w/axios'

const api = { 
  deleteExamResults: (examId, classId) => wAxios.delete_auth(
    `/v1/teacher/examtemplates/${examId}/exams/${classId}`
  ),
  exams: (examId, params) => wAxios.get_auth(
    `/v1/teacher/examtemplates/${examId}/exams`, { params }
  )
}

export default {

  components: {
    'z-loader': () => import('@/plugins/app@components/loader/z-loader.vue')
  },

  data() {
    return {
      currentPage: +this.$route.query?.page || 1,
      examId: this.$route.params.id,
      loading: false,
      totalExams: null,
      perPage: 8,
      exams: []
    }
  },
  
  async mounted() {
    if (!this.examId) {
      return
    }

    this.loading = true
    await this.loadExams()
  },

  methods: {
    async loadExams(event) {
      const page = (event != null && event != undefined) ? event : this.currentPage
      try {
        const { data: { data, meta } } = await api.exams(this.examId, { page, limit: this.perPage })
        this.exams = data
        this.totalExams = meta.total
        this.currentPage = page
      } catch(err) {
        console.error(err)
      } finally {
        this.loading = false
      }
    },

    async deleteExamResults(classId) {
      this.$bvModal.msgBoxConfirm(
        'Naozaj chcete vymazať tieto výsledky?', 
        {
          title: 'Vymazať výsledky?',
          okTitle: 'Vymazať',
          okVariant: 'a-primary',
          cancelTitle: 'Zrušiť',
          cancelVariant: 'a-primary--outline',
          buttonSize: 'sm',
          hideHeaderClose: false
        }
      ).then(async value => {
        if (value) {
          const classIndex = this.exams.findIndex(exam => exam.id == classId)
          await api.deleteExamResults(this.examId, classId).then(() => this.exams.splice(classIndex, 1))
        }
      }).catch(err => console.error('stop exam problem', err))
    }
  }
}
